import React, { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/contacto.css";
import icono1 from "../assets/img/icono-contacto.png";
import icono2 from "../assets/img/icono-direccion.png";
import img1 from "../assets/img/gas-sultana-contacto-img-01.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from 'react-helmet';

const Contacto = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.servicio == "Tipo de consulta") { error = true; msj = "Selecciona una opción"; }
        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.servicio.length <= 1) { error = true; msj = "El campo servicio es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="contacto">
            {/* META TAGS */}
            <Helmet>
                <title>Contacto | Gas Sultana</title>

                <link rel="canonical" href=" https://www.gassultana.com/contacto" />
                <meta name="description"
                    content="¿Necesitas ayuda o información sobre nuestros servicios? Contáctanos en la sección de contacto de Gas Sultana. Estamos para atenderte." />

                <meta property="og:url" content="https://www.gassultana.com/contacto" />
                <meta property="og:title" content="Contacto | Gas Sultana" />
                <meta property="og:type" content="website" />
                <meta property="og:description"
                    content="¿Necesitas ayuda o información sobre nuestros servicios? Contáctanos en la sección de contacto de Gas Sultana. Estamos para atenderte." />
                <meta property="og:site_name" content="Gas Sultana" />
                <meta property="og:image" content="{{ asset('assets/img/favicon.ico') }}" />

                <meta name="twitter:title" content="Contacto | Gas Sultana" />
                <meta name="twitter:description"
                    content="¿Necesitas ayuda o información sobre nuestros servicios? Contáctanos en la sección de contacto de Gas Sultana. Estamos para atenderte." />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:image" content="{{ asset('assets/img/favicon.ico') }}" />

                <script type="application/ld+json">
                    {`
                            {
                                ""@context": "https://schema.org",
                                "@type": "LocalBusiness",
                                "name": "Contacto Gas Sultana",
                                "image": "",
                                "@id": "https://www.gassultana.com/contacto",
                                "url": "https://www.gassultana.com/contacto",
                                "telephone": "81 8360 1100",
                                "priceRange": "$",
                                "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "Carretera a Reynosa",
                                "addressLocality": "Guadalupe",
                                "postalCode": "67190",
                                "addressCountry": "MX"
                                
                                }
                            }
                        `}
                </script>
            </Helmet>

            <Header />

            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white">
                            <h1 className="fw-bold fs-50"> Contacto </h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold">
                                ESCRÍBRENOS, ESTAMOS PARA ATENDERTE
                            </h2>
                            <p className="fw-bold">Información de Contacto</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center">
                                <img src={icono2} alt="Contacto de Gas Sultana - Ubicación" className="icono ms-md-4" />
                                <p className="ms-4"><span className="fw-bold fs-18">OFICINAS</span>
                                    <p className="mt-3">
                                        <a href="https://g.co/kgs/qiqswtD">
                                            Carretera a Reynosa KM 3.5.<br />
                                            Col. San Sebastián,<br />
                                            67190 Guadalupe, N.L. México.
                                        </a>
                                    </p>
                                </p>
                            </div>
                            <div className="d-flex">
                                <img src={icono1} alt="Contacto de Gas Sultana - Contacto" className="icono ms-md-4" />
                                <p className="ms-4"><span className="fw-bold fs-18">CONTACTO</span>
                                    <p className="mt-3">
                                        <a href="tel:52818360.1100">+52 (81) 8360 1100</a><br />
                                        <a href="mailto:pedidos@gassultana.com">contacto@gassultana.com</a>
                                    </p>
                                </p>
                            </div>
                            <div className="col-12 text-center mt-3">
                                <img src={img1} alt="Contacto de Gas Sultana - Gas estacionario" className="w-75" />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <form className="bg-dark-grey" onSubmit={onSubmitHandler}>
                                <input type="text" name="nombre" placeholder="Nombre:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.nombre || ""} required />
                                <input type="email" name="email" placeholder="Email:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.email || ""} required />

                                <input type="tel" name="telefono" placeholder="Télefono:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.telefono || ""} required />
                                <div>
                                    <select name="servicio" placeholder="Tipo de consulta:" className="form-select rounded-0 mb-2"
                                        onChange={handleChange} value={lead.servicio || ""} required>
                                        <option selected value="" >Tipo de consulta</option>
                                        <option value="Pedido">Pedido</option>
                                        <option value="Soporte">Soporte Técnico</option>
                                    </select>
                                </div>

                                <textarea rows={5} placeholder="Mensaje:" name="mensaje" className="form-control rounded-0 mb-4"
                                    onChange={handleChange} value={lead.mensaje || ""} ></textarea>
                                <button type="submit" className="btn-cta fw-bold"> ENVIAR </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC3 */}
            <div className="sec3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold">
                                SUCURSALES
                            </h2>
                            <p className="mb-md-5">Contamos con más de 40 sucursales en el estado de Nuevo León.</p>
                            <iframe className="w-100"
                                src="https://www.google.com/maps/d/embed?mid=1t2qfKCS2r4ht-JqP1DVGflGtcB40Ymg&ehbc=2E312F"
                                height="600"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Contacto;