import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/cilindros.css";
import beneficios from "../assets/img/gassultana-imagen-beneficios.jpg";
import icono1 from "../assets/img/gassultana-icono-fuego-azul.png";
import icono2 from "../assets/img/gassultana-icono-emisiones.png";
import icono3 from "../assets/img/gassultana-icono-almacenar.png";
import icono4 from "../assets/img/gassultana-icono-fuente-energia.png";
import icono5 from "../assets/img/gassultana-icono-cerrar-el-gas.png";
import icono6 from "../assets/img/gassultana-icono-cambio-2-5-años.png";
import icono7 from "../assets/img/gassultana-icono-evitar-uso-aparatos.png";
import img1 from "../assets/img/gassultana-imagen-servicio.jpg";
import img2 from "../assets/img/gassultana-imagen-precauciones.jpg";
import img3 from "../assets/img/gassultana-imagen-seguridad.jpg";
import img4 from "../assets/img/gassultana-imagen-gas-estacionario.jpg";
import img5 from "../assets/img/gassultana-imagen-vehicular.jpg";
import check from "../assets/img/gassultana-icono-check.png";
import footer from "../assets/img/gas-sultana-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";


const Cilindros = () => {
    const currentUrl = window.location.href;

    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.servicio.length <= 1) { error = true; msj = "El campo servicio es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="cilindros">
            {/* META TAGS */}
            <Helmet>
                <title>Recarga de cilindros de gas LP | Gas Sultana </title>
                <meta name="description" content="En Gas Sultana confía en nosotros para la recarga de cilindros de gas. Servicio confiable y rápido para mantener tu suministro de gas LP al máximo." />
                <link rel="canonical" href={currentUrl} />
                <script type="application/ld+json">
                    {`
                        {
                            "@context":"https://schema.org",
                            "@type":"FAQPage",
                            "mainEntity":[
                                {
                                    "@type":"Question",
                                    "name":"¿Con qué frecuencia debo recargar mi cilindro de gas LP?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"La frecuencia de recarga de un cilindro de gas LP depende de su consumo individual y del tamaño del cilindro. Una forma de verificar si es necesario realizar una recarga es encender la estufa y observar el color e intensidad de la llama. Si esta es muy débil o la llama es de color rojizo, es un buen indicador de que se debe realizar una recarga de gas LP."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"¿Qué precauciones de seguridad debo seguir al recargar un cilindro de gas LP?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"Al realizar una recarga de cilindros de gas, se deben seguir ciertas precauciones para garantizar la seguridad durante este proceso. Algunas de ellas son las siguientes: Tener apagada cualquier fuente de ignición, como llamas o dispositivos eléctricos. No fumar cerca del área de recarga. Evitar llenar el cilindro en exceso; dejar espacio para la expansión del gas. Verificar que la válvula de llenado esté cerrada correctamente después de la recarga. Realizar la recarga en un área bien ventilada. Es importante recordar que las recargas deben hacerse únicamente por personal debidamente capacitado."
                                    }
                                },
                                {
                                    "@type":"Question",
                                    "name":"¿Se puede rellenar un tanque de gas viejo?",
                                    "acceptedAnswer":{
                                        "@type":"Answer",
                                        "text":"No es recomendable rellenar un tanque de gas LP antiguo, ya que este podría estar dañado por el paso del tiempo. Es importante que un profesional certificado revise si el tanque se encuentra en condiciones adecuadas para ser rellenado."
                                    }
                                }
                            ]
                        }
                    `}
                </script>

            </Helmet>

            <Header />

            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className="fw-bold fs-50 text-white mt-4"> Realiza tu recarga de cilindros de gas  </h1>
                            <p className="mt-4 text-white fs-18 mb-5"> Obtén tu recarga de gas lp en cualquiera de nuestra sucursales </p>
                            <a href="#contacto" className="btn-cta mt-2 mb-4 fw-bold"> HAZ TU PEDIDO </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2 d-pad mt-4 mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={img1} alt=" Recarga de cilindros de gas - Parrilla para gas lp " className="w-100" />
                        </div>
                        <div className="col-md-5 ms-md-5">
                            <h2 className="fs-26 fw-bold"> Conoce nuestro servicio </h2>
                            <p className="fs-18 mt-4">
                                En Gas Sultana, entendemos que tu comodidad y seguridad son lo más importante. Por ello, te ofrecemos nuestro
                                servicio de recarga de cilindros de gas lp, diseñado para facilitar tu vida y mantener tu hogar siempre abastecido.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC3 */}
            <div className="sec3 bg-grey d-pad">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-12 text-center mt-5">
                            <h2 className="fs-26 fw-bold">
                                Descubre los beneficios de realizar la recarga <br /> de cilindros de gas lp con nosotros
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 d-flex align-items-center mb-md-0 mb-4">
                            <img src={check} className="w-25" />
                            <p className="fs-26 fw-bold mb-0 ms-2"> Gas a domicilio </p>
                        </div>
                        <div class="col-md-4 d-flex align-items-center mb-md-0 mb-4">
                            <img src={check} className="w-25" />
                            <p className="fs-26 fw-bold mb-0 ms-2"> Suministro constante </p>
                        </div>
                        <div class="col-md-4 d-flex align-items-center mb-md-0 mb-4">
                            <img src={check} className="w-25" />
                            <p className="fs-26 fw-bold mb-0 ms-2"> Servicio confiable </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 d-flex align-items-center">
                            <p className="fs-18 mt-4">
                                Evita largas filas y trayectos. Nuestro servicio de recarga de cilindros de gas te llevará tu suministro
                                directamente a tu puerta.
                            </p>
                        </div>
                        <div class="col-md-4 d-flex align-items-center">
                            <p className="fs-18 mt-4">
                                Mantén tus cilindros siempre con gas, programando una recarga de gas lp antes de que este se agote.
                                Así nunca te quedarás sin gas.
                            </p>
                        </div>
                        <div class="col-md-4 d-flex align-items-center">
                            <p className="fs-18 mt-4">
                                Nuestro equipo de técnicos altamente capacitados se asegurará de que cada recarga de cilindros de gas se
                                realice de manera segura y eficiente.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC4 */}
            <div className="sec4 d-pad">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <h2 className="fs-26 fw-bold">
                                Precauciones en la <br />
                                recarga de gas lp
                            </h2>
                            <p className="mt-4">
                                A fin de garantizar la seguridad durante la recarga de gas lp es <br />
                                importante tomar las siguientes precauciones:
                            </p>
                            <li>
                                Asegurarse de que los contenedores cumplan con las normas <br /> oficiales mexicanas en cuanto a su fabricación y
                                que no <br /> presenten daños ni abolladuras.
                            </li>
                            <li>
                                Colocar los tanques o cilindros en áreas bien ventiladas y <br /> despejadas.
                            </li>
                            <li>
                                Permitir la recarga de gas lp únicamente a personal certificado.
                            </li>
                            <li>
                                Utilizar exclusivamente tuberías fabricadas para el uso en cilindros <br /> de gas, evitando el plástico.
                            </li>
                        </div>
                        <div className="col-md-6 mt-md-0 mt-5">
                            <img src={img2} alt=" Recarga de cilindros de gas - Precauciones en recarga " className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC5 */}
            <div className="sec5 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fs-26 fw-bold mb-5">
                                VENTAJAS DEL USO DE GAS LP
                            </h2>
                        </div>
                    </div>
                    <div class="row mt-4 mb-5">
                        <div class="col-md-6 d-flex align-items-center">
                            <img src={icono1} alt=" Recarga de cilindros de gas - Poder calórico " className="w-25" />
                            <p className="fs-18 mb-0 ms-4"> Mayor poder calórico </p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center mt-md-0 mt-5">
                            <img src={icono2} alt=" Recarga de cilindros de gas - menor contaminación " className="w-25" />
                            <p className="fs-18 mb-0 ms-4"> Menor contaminación </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 d-flex align-items-center">
                            <img src={icono3} alt=" Recarga de cilindros de gas - tanque gas " className="w-25" />
                            <p className="fs-18 mb-0 ms-4"> Versatilidad </p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center mt-md-0 mt-5">
                            <img src={icono4} alt=" Recarga de cilindros de gas - más económico " className="w-25" />
                            <p className="fs-18 mb-0 ms-4"> Más económico </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC6 */}
            <div className="sec6 text-center mt-md-0 mt-5 mb-md-0 mb-5">
                <div className="container">
                    <a href="#contacto" className="btn-cta fw-bold"> REALIZA TU PEDIDO </a>
                </div>
            </div>
            {/* SEC7 */}
            <div className="sec7 d-pad">
                <div className="row">
                    <div className="col-md-6">
                        <img src={img3} alt=" Recarga de cilindros de gas - comprometidos con seguridad " className="w-100" />
                    </div>
                    <div className="col-md-5 ms-md-5">
                        <h2 className="fs-26 fw-bold"> Comprometidos con la seguridad </h2>
                        <p className="fs-18 mt-md-4">
                            En Gas Sultana, nuestra prioridad es la seguridad de nuestros clientes y comunidades. Mantenemos un compromiso
                            firme con los estándares más altos de seguridad en todo nuestro servicio de recarga de gas lp, desde la supervisión
                            del estado de los contenedores hasta la capacitación de nuestro personal certificado.
                        </p>
                        <p>
                            Nuestra misión es proporcionar tranquilidad a nuestros clientes, garantizando que la recarga de gas lp se realice con
                            la máxima seguridad y eficiencia.
                        </p>
                    </div>
                </div>
            </div>
            {/* SEC8 */}
            <div className="sec8 d-pad">
                <div className="container">
                    <div className="row mb-5 ">
                        <div className="col-md-12 text-center">
                            <h2 className="fs-26 fw-bold"> Recomendaciones en el uso y recarga de gas lp </h2>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div class="col-md-4">
                            <div class="d-flex icon">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono3} alt=" Recarga de cilindros de gas " className="img mb-4" />
                                    <p class="fs-18">
                                        Por cuestion de seguridad, tu tanque no debe de ser llenado a más del 90% de su capacidad.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex icon">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono1} alt=" Recarga de cilindros de gas " className="img mb-4" />
                                    <p class="fs-18">
                                        La llama producida debe ser azul. Si es amarilla o naranja, es sinónimo de una mala combustión.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex icon">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono5} alt=" Recarga de cilindros de gas " className="img mb-4" />
                                    <p class="fs-18">
                                        Recuerda cerrar el gas en ausencias largas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div class="col-md-4">
                            <div class="d-flex icon">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono6} alt=" Recarga de cilindros de gas " className="img mb-4" />
                                    <p class="fs-18">
                                        Las válvulas principales se deben cambiar cada 2 a 5 años.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex icon">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono7} alt=" Recarga de cilindros de gas " className="img mb-4" />
                                    <p class="fs-18">
                                        Evita el uso de aparatos que utilicen gas en lugares sin ventilación.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex icon">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono3} alt=" Recarga de cilindros de gas " className="img mb-4" />
                                    <p class="fs-18">
                                        Descarga nuestra guía para entender tu tanque y así prevenir cualquier accidente.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 text-center">
                        <div class="col-md-12">
                            <h2 className="fw-bold mb-5 mt-5"> Conoce nuestros otros servicios de recarga de gas lp </h2>
                        </div>
                    </div>
                    <div class="row intermediate position-relative">
                        <div class="col-md-6 mb-md-0 mb-5">
                            <img src={img4} alt=" Recarga de cilindros de gas - sucursal de gas sultana " className="w-100" />
                            <div className="position-relative text-center mt-md-3 mt-5">
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                    <Link to={'/gas-estacionario'} className="btn-cta">REALIZA TU PEDIDO</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src={img5} alt=" Recarga de cilindros de gas - servicio de conversión " className="w-100" />
                            <div className="position-relative text-center mt-md-3 mt-5">
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                    <Link to={'/vehicular'} className="btn-cta">REALIZA TU PEDIDO</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC9*/}
            <div className="sec9 bg-grey">
                <div class="container">
                </div>
            </div>
            {/* SEC10 */}
            <div className="faqs">
                <div className="container">
                    <h3 className="fw-bold fs-25 mb-5 text-center">Preguntas frecuentes sobre nuestro servicio de gas a domicilio</h3>
                    <div class="accordion" id="accordionExample">
                        {/* Pregunta1 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Con qué frecuencia debo recargar mi cilindro de gas LP?
                                </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    La frecuencia de recarga de un cilindro de gas LP depende de su consumo individual y del tamaño del cilindro. Una forma
                                    de verificar si es necesario realizar una recarga es encender la estufa y observar el color e intensidad de la llama.
                                    Si esta es muy débil o la llama es de color rojizo, es un buen indicador de que se debe realizar una <strong> recarga de gas LP. </strong>
                                </div>
                            </div>
                        </div>
                        {/* Pregunta2 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Qué precauciones de seguridad debo seguir al recargar un cilindro de gas LP?
                                </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Al realizar una recarga de cilindros de gas, se deben seguir ciertas precauciones para garantizar
                                    la seguridad durante este proceso. Algunas de ellas son las siguientes:
                                    <p className="mt-3">
                                        <li>
                                            Tener apagada cualquier fuente de ignición, como llamas o dispositivos eléctricos.
                                        </li>
                                        <li>
                                            No fumar cerca del área de recarga.
                                        </li>
                                        <li>
                                            Evitar llenar el cilindro en exceso; dejar espacio para la expansión del gas.
                                        </li>
                                        <li>
                                            Verificar que la válvula de llenado esté cerrada correctamente después de la recarga.
                                        </li>
                                        <li>
                                            Realizar la recarga en un área bien ventilada.
                                        </li>
                                    </p>
                                    Es importante recordar que las recargas deben hacerse únicamente por personal debidamente capacitado.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta3 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿Cuál es el proceso para recargar un cilindro de Gas LP?
                                </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                </div>
                            </div>
                        </div>
                        {/* Pregunta4 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Se puede rellenar un tanque de gas viejo?
                                </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    No es recomendable rellenar un tanque de gas LP antiguo, ya que este podría estar dañado por el paso del tiempo. Es importante que un profesional certificado revise
                                    si el tanque se encuentra en condiciones adecuadas para ser rellenado.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC11 */}
            <div className="sec11 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mb-5">Obtén un suministro <br /> constante de Gas LP para <br /> tu hogar o negocio.</h2>
                            <img src={footer} alt=" Recarga de cilindros de gas - gas estacionario " className="w-100" />
                        </div>
                        <div className="col-md-5">
                            <p className="mb-4">
                                ESCRÍBENOS, ESTAMOS PARA ATENDERTE
                            </p>
                            <div className="contacto" id="form">
                                <form onSubmit={onSubmitHandler}>
                                    <input type="text" name="nombre" placeholder="Nombre:" onChange={handleChange} value={lead.nombre || ""} required />
                                    <input type="email" name="email" placeholder="Email:" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="servicio" placeholder="Servicio:" onChange={handleChange} value={lead.servicio || ""} required />
                                    <input type="tel" name="telefono" placeholder="Teléfono:" onChange={handleChange} value={lead.telefono || ""} required />
                                    <textarea name="mensaje" placeholder="Mensaje:" onChange={handleChange} value={lead.mensaje || ""} required />
                                    <button type="submit" className="btn-cta mt-3"> ¡CONTÁCTANOS! </button>
                                </form>
                            </div>
                        </div>
                        <div className="coml-md-1"></div>
                    </div>
                </div>
            </div>
            {/* FOOTER */}
            <Footer />
        </div >
    );
};

export default Cilindros;