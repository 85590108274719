import React, { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/sucursales.css";
import beneficios from "../assets/img/gassultana-imagen-beneficios.jpg";
import icono1 from "../assets/img/icono-baja-velocidad.png";
import icono2 from "../assets/img/icono-apaga-motor.png";
import icono3 from "../assets/img/icono-no-celular.png";
import icono4 from "../assets/img/icono-no-fumar.png";
import img1 from "../assets/img/gassultana-sucursales-estacionario.jpg";
import img2 from "../assets/img/gassultana-sucursales-cilindros.jpg";
import img3 from "../assets/img/gassultana-sucursales-vehicular.jpg";
import footer from "../assets/img/gas-sultana-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from 'react-helmet';

const Sucursales = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.servicio.length <= 1) { error = true; msj = "El campo servicio es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="sucursales">
            {/* META TAGS */}
            <Helmet>
            <title>Sucursales | Gas Sultana</title>

            <link rel="canonical" href=" https://www.gassultana.com/sucursales" />
                <meta name="description"
                    content="Descubre nuestras sucursales de Gas Sultana cerca de ti. Ofrecemos distribución confiable de gas LP con atención personalizada y cobertura en toda la región. ¡Conócenos ahora!" />

                    <meta property="og:title" content="Sucursales | Gas Sultana" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="{{ asset('assets/img/favicon.ico') }}" />
                    <meta property="og:url" content=" https://www.gassultana.com/sucursales" />
                    <meta property="og:description"
                        content="Descubre nuestras sucursales de Gas Sultana cerca de ti. Ofrecemos distribución confiable de gas LP con atención personalizada y cobertura en toda la región. ¡Conócenos ahora!" />

            </Helmet>

            <Header />

            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white">
                            <h1 className="fw-bold fs-50"> Sucursales </h1>
                            <a href="#contacto" className="btn-cta mt-5 mb-5"> REALIZA TU PEDIDO </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2 d-pad">
                <div className="container" id="sucursales-sec">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="mt-4 fw-bold ff-lato fs-18">
                                Te mostramos el mapa con las ubicaciones de nuestras estaciones de carburación.<br />
                                Visita la sucursal más cercana a ti.
                            </p>
                            <p className="ff-lato fw-bold fs-26 mb-5"> ¡Te esperamos! </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <iframe className="w-100"
                                src="https://www.google.com/maps/d/embed?mid=1t2qfKCS2r4ht-JqP1DVGflGtcB40Ymg&ehbc=2E312F"
                                height="600"></iframe>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div class="accordion accordion-flush row" id="accordionFlushExample">
                                    <div class="accordion-item col-md-3 border-0 mt-md-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                                VILLA UNIÓN
                                            </button>
                                        </h3>
                                        <div id="flush-collapse1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Gral. Pérez Treviño 801, Zona Centro, 26600 Villa Unión, Coah.</p>
                                                    <p className="fw-bold">+52 862 623 0023</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-md-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                                TEOFILO SALINAS
                                            </button>
                                        </h3>
                                        <div id="flush-collapse2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Teófilo Salinas Garza 521, 67265 Juárez, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-md-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                                SANTA CATARINA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>A Villa de García 158, Industrial las Palmas, 66368 Santa Catarina, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-md-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                                SAN ROQUE
                                            </button>
                                        </h3>
                                        <div id="flush-collapse4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carr. a San Roque 307 Monte Cristal, N.L. 67298, 67298 Juárez, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                                SAN BERNABÉ
                                            </button>
                                        </h3>
                                        <div id="flush-collapse5" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Calle Nepenta, San Bernabé X Sector, 64105 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 2894 1394</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                                                PUEBLO NUEVO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse6" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Calle Carretera, A Villa de Juárez, 66640 Ciudad Apodaca, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                                                PLANTA LAMPAZOS
                                            </button>
                                        </h3>
                                        <div id="flush-collapse7" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>65070, Centro de Lampazos de Naranjo, 65070 Lampazos de Naranjo, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                                                PEAQUERÍA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse8" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Nuevo León 186, 66650 Pesquería, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                                PABLO LIVAS
                                            </button>
                                        </h3>
                                        <div id="flush-collapse9" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Avenida Pablo Livas, Jardines de Andalucía, 67193 Guadalupe, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                                MORONES PRIETO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse10" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Avenida Ignacio Morones Prieto, 67140 Guadalupe, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                                MEZQUITAL
                                            </button>
                                        </h3>
                                        <div id="flush-collapse11" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Avenida Aceros, Cerradas de Casa Blanca, 66632 San Nicolás de los Garza, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                                LINCOLN
                                            </button>
                                        </h3>
                                        <div id="flush-collapse12" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Avenida Abraham Lincoln 4000, Barrio Estrella, 64400 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                                LIBRAMIENTO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse13" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Unidad Deportiva 708-4S, Portal del Fraile, 66064 Cd Gral Escobedo, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                                LERMAS
                                            </button>
                                        </h3>
                                        <div id="flush-collapse14" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Avenida A Reynosa Km. 3.5, San Sebastián, 67198 Guadalupe, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                                LAS TORRES
                                            </button>
                                        </h3>
                                        <div id="flush-collapse15" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av Lázaro Cárdenas y Av Alfonso Martínez Domínguez, Los Rosales, 64750, Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                                LA FAMA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse16" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Boulevard Gustavo Díaz Ordaz 130, La Fama, 66170 Santa Catarina, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                                LA CRUZ
                                            </button>
                                        </h3>
                                        <div id="flush-collapse17" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Morelos 431, Cabecera Municipal, 66008 García, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                                INDEPENDENCIA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse18" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Avenida Ignacio Morones Prieto # 300, Independencia, 64720 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                                GUERRERO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse19" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Vicente Guerrero # 1708, Obrerista, 64490 Monterrey, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                                ESTANZUELA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse20" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carretera Nacional 271, Vieja Estanzuela, 64984 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                                ESCOBEDO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse21" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Avenida las Torres 500, Sin Nombre de Colonia 2, 66062 Ciudad General Escobedo, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                                DOS RÍOS
                                            </button>
                                        </h3>
                                        <div id="flush-collapse22" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Israel Cavazos S/N, 67169 Guadalupe, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1313</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                                CASA BLANCA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse23" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carretera Monterrey - Saltillo km. 35+050, 67850 García, N.L.</p>
                                                    <p className="fw-bold">+52 81 1022 0195</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                                BERNARDO REYES
                                            </button>
                                        </h3>
                                        <div id="flush-collapse24" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Bernardo Reyes 6113, Ferrocarrilera, 64250 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                                APOCADA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse25" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carretera a Miguel Alemán S/N, Esquina con 5 de Mayo, Apodaca Centro, 66600 Ciudad Apodaca, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                                SANTA ROSA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse26" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Antiguo Camino a Santa Rosa #404, Teresita, 66605 Ciudad Apodaca, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                                HUINALÁ
                                            </button>
                                        </h3>
                                        <div id="flush-collapse27" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carretera a Huinalá km 1.4, Valle de Huinalá, 66634 Apodaca, N.L.</p>
                                                    <p className="fw-bold">+52 81 1022 0195</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse28">
                                                AV. ACAPULCO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse28" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p> Av, Blvd. Acapulco SN, Privadas de San Miguelito, 67110 Cd Apodaca, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse29" aria-expanded="false" aria-controls="flush-collapse29">
                                                ACUEDUCTO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse29" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>C. Cam. a las Espinas 1000, Santa Mónica Sector 8, 67250 Jardines de la Silla, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                                BUSTAMANTE
                                            </button>
                                        </h3>
                                        <div id="flush-collapse30" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Abraham Buentello, Las Flores, 65150 Bustamante, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                                INSURGENTES
                                            </button>
                                        </h3>
                                        <div id="flush-collapse31" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Aaron Sáenz Garza S/N, Santa Maria, 64650 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                                CHURUBUSCO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse32" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Constituyentes de Nuevo León #102, Churubusco, 64590 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                                SAN PEDRO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse33" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Sta. Bárbara 474, Zona Los Callejones, 66228 San Pedro Garza García, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                                JUÁREZ
                                            </button>
                                        </h3>
                                        <div id="flush-collapse34" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Lic. Arturo B. de La Garza 916, Joaquín Garza y Garza, 67250 Juárez, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                                PEDRERAS
                                            </button>
                                        </h3>
                                        <div id="flush-collapse35" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Antiguo Camino a San Miguel Lázaro Cárdenas, del Norte, 66050 Cd Gral. Escobedo, N.L.</p>
                                                    <p className="fw-bold">+52 8110220284</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                                SENDERO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse36" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Esq, Los Pinos, Av. Sendero Divisorio, Hacienda de Anáhuac, 66055 Cd Gral. Escobedo, N.L.</p>
                                                    <p className="fw-bold">+52 8183601100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                                GARCÍA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse37" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Sor Juana I, Sor Juana Inés de La Cruz s/n, Fray Juan Antonio de Sobrevilla, 66000 García, N.L.</p>
                                                    <p className="fw-bold">+52 8183601100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                                CADEREYTA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse38" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carr. Juárez - Cadereyta km 293, Sin Nombre de Col 1, 67450 Cadereyta Jiménez, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                                SAN MIGUEL
                                            </button>
                                        </h3>
                                        <div id="flush-collapse39" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carr. a San Miguel S/N, Privadas de San Miguelito, 66649 Monterrey, N.L.</p>
                                                    <p className="fw-bold">+52 81 8360 1100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                                HIDALGO
                                            </button>
                                        </h3>
                                        <div id="flush-collapse40" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Hidalgo Centro, 65600 Hidalgo, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                                EL JARAL
                                            </button>
                                        </h3>
                                        <div id="flush-collapse41" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Monterrey-Monclova, 66080 Cd Gral. Escobedo, N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                                ZUAZUA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse42" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Carr. a Gral. Zuazua, Real de Palmas, 65760 N.L.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse43" aria-expanded="false" aria-controls="flush-collapse43">
                                                NOGALAR
                                            </button>
                                        </h3>
                                        <div id="flush-collapse43" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Universidad 1000 Col. Cuauhtémoc, San Nicolás de los Garza 66450. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item col-md-3 border-0 mt-4">
                                        <h3 class="accordion-header text-center">
                                            <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse44" aria-expanded="false" aria-controls="flush-collapse44">
                                                LA MODERNA
                                            </button>
                                        </h3>
                                        <div id="flush-collapse44" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-0 mt-3">
                                                <div className="card card-body shadow">
                                                    <p>Av. Ruiz Cortines 3265, Col. Coyoacán, Monterrey 64510.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC3 */}
            <div className="sec3 d-pad bg-grey">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-5 fs-25"> Recomendaciones al momento de estar en una estación de carburación. </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono1} className="w-25" alt="Sucursales de Gas sultana - Manejar con precaución" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono2} className="w-25" alt="Sucursales de Gas sultana - Apagar tu motor" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono3} className="w-25" alt="Sucursales de Gas sultana - No utilizar celular" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono4} className="w-25" alt="Sucursales de Gas sultana -" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono1} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold"> Maneja con precaución y a <br /> baja velocidad </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono2} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold"> Apaga tu motor antes de <br /> que inicie la carga </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono3} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold"> No utilices tu celular </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono4} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold"> No fumes </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC4 */}
            <div className="sec4 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-4 fs-25 ff-lato text-center"> Conoce nuestros servicios. </h2>
                        </div>
                    </div>
                    <div className="row mt-md-5 mt-3">
                        <div className="col-md-6 mobile-item">
                            <img src={img1} className="w-100" alt="Sucursales de Gas sultana -" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="fw-bold fs-25 ff-lato mt-md-5 mt-3"> Estacionario </h2>
                            <p className="mt-3"> Llevamos el gas lp hasta tu hogar o negocio <br /> mediante nuestras unidades especializadas. </p>
                            <Link to={'/gas-estacionario'} className="btn-cta mt-4 mb-md-0 mb-5">MÁS INFORMACIÓN</Link>
                        </div>
                        <div className="col-md-6 desktop-item">
                            <img src={img1} className="w-100" alt="Sucursales de Gas sultana -"/>
                        </div>
                    </div>
                    <div className="row mt-md-5 mt-3">
                        <div className="col-md-6">
                            <img src={img2} className="w-100" alt="Sucursales de Gas sultana -" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="fw-bold fs-25 ff-lato mt-md-5 mt-3"> Cilindros </h2>
                            <p className="mt-3"> Llevamos el gas lp hasta tu hogar o negocio mediante <br /> nuestras unidades especializadas. </p>
                            <Link to={'/cilindros'} className="btn-cta mt-4 mb-md-0 mb-5">MÁS INFORMACIÓN</Link>
                        </div>
                    </div>
                    <div className="row mt-md-5 mt-3">
                        <div className="col-md-6 mobile-item">
                            <img src={img3} className="w-100" alt="Sucursales de Gas sultana -" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="fw-bold fs-25 ff-lato mt-md-5 mt-3"> Vehicular </h2>
                            <p className="mt-3"> Contamos con un taller exclusivo de conversión <br /> de unidades de gasolina a gas LP.</p>
                            <Link to={'/vehicular'} className="btn-cta mt-4">MÁS INFORMACIÓN</Link>
                        </div>
                        <div className="col-md-6 desktop-item">
                            <img src={img3} className="w-100" alt="Sucursales de Gas sultana -" />
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC5 */}
            <div className="sec5 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fw-bold mt-4 mb-4 fs-25 ff-lato text-center desktop-item text-light-grey"> ESCRÍBENOS, ESTAMOS PARA ATENDERTE </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"> </div>
                        <div className="col-md-6">
                            <p className="fw-bold mb-4 fs-25 ff-lato text-center mobile-item mt-4">Escríbenos, estamos para atenderte</p>
                            <form className="bg-dark-grey" onSubmit={onSubmitHandler}>
                                <input type="text" name="nombre" placeholder="Nombre:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.nombre || ""} required />
                                <input type="email" name="email" placeholder="Email:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.email || ""} required />
                                <input type="text" name="servicio" placeholder="Servicio:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.servicio || ""} required />
                                <input type="tel" name="telefono" placeholder="Télefono" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.telefono || ""} required />
                                <textarea rows={5} placeholder="Mensaje:" name="mensaje" className="form-control rounded-0 mb-4"
                                    onChange={handleChange} value={lead.mensaje || ""} ></textarea>
                                <button type="submit" className="btn-cta fw-bold"> ENVIAR </button>
                            </form>
                        </div>
                        <div className="col-md-3"> </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Sucursales;