import waIcon from "../../assets/img/logo-wa.png";
import logoB from "../../assets/img/logo-b.png";
import phone from "../../assets/img/logo-tel-blanco.png";

const Footer = () => {
    return (<>
        <div className="footer bg-dark-grey-01" id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-12">
                        <img src={logoB} className="logo" alt="Gas LP - Logo Gas Sultana blanco (1) " />
                    </div>
                    <div className="col-md col-6">
                        <p className="text-white ls-25 fs-12 fw-bold">OFICINAS</p>
                        <p className="text-grey fs-12">
                            Carretera a Reynosa KM 3.5<br />
                            Col. San Sebastián<br />
                            Guadalupe, N.L.<br />
                            México. 67190
                        </p>
                    </div>
                    <div className="col-md col-6">
                        <p className="text-white ls-25 fs-12 fw-bold">CONTACTO</p>
                        <p className="fs-12">
                            <a href="tel:52818360.1100">+52 (81) 8360 1100</a><br />
                            <a href="mailto:pedidos@gassultana.com">contacto@gassultana.com</a>
                        </p>
                        <a className="fs-12" href="https://www.gassultana.com/avisos-de-privacidad.pdf"
                            target="_blank" rel="noreferrer">
                            Avisos de Privacidad
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="mb-0 text-grey fs-10">Gas SULTANA. Todos los derechos reservados.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright bg-dark-grey text-center">
            <div className="container">
                <div className="row">
                    <div className="col-md">
                        <p className="mb-0 text-grey fs-10">GAS SULTANA FORMA PARTE DE <a
                            href="https://www.gruporg.com/">GRUPO RG</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="sticky-btn-footer bg-white w-100 text-center mobile-item ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p className="fw-bold fs-18 text-red">
                            ¡Solicita tu recarga de Gas LP Hoy!
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <a href="#" className="btn-cta wa d-flex align-items-center justify-content-center">
                            <img src={waIcon} className="icon-button me-2" alt="Gas LP - Whatsapp Button" />
                            <span>Envianos<br /> WhatsApp</span>
                        </a>
                    </div>
                    <div className="col-md-6 col-6">
                        <a href="tel:528183601100" className="btn-cta call d-flex align-items-center justify-content-center">
                            <img src={phone} className="icon-button me-2" alt="Gas LP -  Phone Button" />
                            <span>Llámanos</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>)
};

export default Footer;