import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/vehicular.css";
import beneficios from "../assets/img/gassultana-imagen-beneficios.jpg";
import icono1 from "../assets/img/gassultana-icono-fuente-energia-economico.png";
import icono2 from "../assets/img/gassultana-icono-reduccion-de-emisiones.png";
import icono3 from "../assets/img/gassultana-icono-ahorro-de-consumo.png";
import icono4 from "../assets/img/gassultana-icono-conversion-a-gas.png";
import footer from "../assets/img/gas-sultana-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Helmet } from 'react-helmet';

const Vehicular = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.servicio.length <= 1) { error = true; msj = "El campo servicio es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
            data.tipo = 'Sultana';
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 145;


            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="vehicular">
            {/* META TAGS */}
            <Helmet>
                <title>  Conversión a gas LP | Gas Sultana </title>
                <meta name="description" content="En Gas Sultana somos expertos en conversión de gas LP para tu comodidad. Convierte a gas LP hoy y disfruta de beneficios económicos y ecológicos." />
                <link rel="canonical" href={currentUrl} />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [{
                                "@type": "Question",
                                "name": "¿Es seguro convertir mi vehículo a gas LP?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Sí, el realizar la conversión a gas LP en tu vehículo no requiere cambios mecánicos que afecten su funcionalidad ni seguridad."
                                }
                            },{
                                "@type": "Question",
                                "name": "¿Qué mantenimiento adicional requiere un vehículo convertido a gas LP?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Los vehículos que pasan por el proceso de conversión a gas LP requieren un servicio de mantenimiento adicional que consiste en cambiar 2 filtros de gas LP cada 10,000 km, pero no te preocupes, su costo es muy bajo."
                                }
                            },{
                                "@type": "Question",
                                "name": "¿Qué tipos de vehículos son aptos para la conversión a gas LP?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Cualquier tipo de vehículo puede pasar por la conversión a gas LP, siempre y cuando sea año 2008 en adelante."
                                }
                            },{
                                "@type": "Question",
                                "name": "¿Puedo seguir usando gasolina después de la conversión a gas LP?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Sí, los sistemas que se instalan para la conversión a gas LP permiten que el auto sea un híbrido entre gasolina y gas LP."
                                }
                            },{
                                "@type": "Question",
                                "name": "¿Cómo se instala el sistema de gas LP en mi vehículo?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "En Gas Sultana realizamos la conversión a gas LP de la siguiente manera: Examinanos tu vehículo para evaluar su compatibilidad con el sistema de gas LP. Colocamos en tu vehículo un sistema de inyección de combustible que consta de un depósito, mangueras, sistema electrónico y de inyección. Realizamos pruebas para garantizar la funcionalidad de la conversión de gasolina a gas LP."
                                }
                            },{
                                "@type": "Question",
                                "name": "¿Cuánto tiempo lleva el proceso de conversión a gas LP?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Realizamos el proceso de conversión a gas LP en 24 horas."
                                }
                            }]
                        }
                    `}
                </script>
            </Helmet>

            <Header />
            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className="fw-bold fs-50 text-light-yellow mt-4"> Conversión a gas LP para tu auto </h1>
                            <p className="mt-4 text-white fs-26"> Usa gas LP en tu vehículo y ahorra hasta un 50% en cada recarga. </p>
                            <a href="#contacto" className="btn-cta mt-2 mb-4 fw-bold"> COMENZAR AHORA </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fs-26 fw-bold"> ¿Qué es la conversión de gasolina a gas LP? </h2>
                            <p className="mt-4 ff-lato">
                                El servicio de <strong> conversión a gas LP </strong> consiste en incluir diversos elementos a tu
                                vehículo para que utilice gas como su <br /> combustible principal.
                            </p>
                            <p className="ff-lato ">
                                Este proceso implica la incorporación de un tanque de almacenamiento de gas,
                                así como ajustes en el sistema de <br /> alimentación y control del motor sin reemplazarlo.
                            </p>
                            <p className="ff-lato">
                                Es muy sencillo realizar la <strong> conversión de gasolina a gas LP </strong> y te brinda grandes beneficios
                                para recorrer tu camino <br /> ahorrado combustible al máximo.
                            </p>
                        </div>
                    </div>
                    <div className="position-relative text-center mt-5">
                        <div className="position-absolute translate-middle start-50">
                            <img src={beneficios} alt=" Conversión de gas lp - conversión de gas lp " className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC3 */}
            <div className="sec3 bg-grey">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fs-26 fw-bold">Beneficios de hacer la conversión a gas LP</h2>
                            <p className="mt-3 mb-5 fs-18"> Comienza a disfrutar los increíbles beneficios de realizar la conversión de gasolina a gas LP en tu auto: </p>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div class="col-md-3">
                            <div class="d-flex icon text-center">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono1} alt=" Conversión de gas lp - Tanque " className="img mb-4" />
                                    <p class="fw-bold fs-18">
                                        Es una fuente de energía más económica.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="d-flex icon text-center">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono2} alt=" Conversión de gas lp - Emisiones " className="img mb-4" />
                                    <p class="fw-bold fs-18">
                                        Reducción de emisiones contaminantes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="d-flex icon text-center">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono3} alt=" Conversión de gas lp - tanque de gasolina " className="img mb-4" />
                                    <p class="fw-bold fs-18">
                                        Ahorro de consumo gracias a su alto poder calorífico.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="d-flex icon text-center">
                                <div class="fs-15 ms-4 ">
                                    <img src={icono4} alt=" Conversión de gas lp - mecánico " className="img mb-4" />
                                    <p class="fw-bold fs-18">
                                        La conversión a gas LP es muy sencilla de realizar.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative text-center mt-5">
                        <div className="box position-absolute translate-middle start-50">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 className="fs-26 fw-bold"> Proceso de conversión de gasolina a gas LP paso a paso </h2>
                                    <p className="mt-4 mb-4">
                                        Para el servicio de conversión de gas LP contamos con un taller exclusivo y <br />
                                        realizamos el proceso de la siguiente manera:
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p> <span className="text-red fw-bold"> 1. </span> Examinanos tu vehículo <br />
                                        para evaluar su <br /> compatibilidad con el <br /> sistema de gas LP. </p>
                                </div>
                                <div class="col-md-4">
                                    <p> <span className="text-red fw-bold"> 2. </span> Colocamos en tu <br />
                                        vehículo un sistema de <br /> inyección de combustible <br /> que consta de un depósito, <br />
                                        mangueras, sistema electrónico y de inyección.  </p>
                                </div>
                                <div class="col-md-4">
                                    <p> <span className="text-red fw-bold"> 3. </span> Realizamos pruebas para <br /> garantizar la funcionalidad <br />
                                        de la conversión a gas LP. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC4*/}
            <div className="sec4 text-center">
                <h2 className="fs-26 fw-bold text-white mb-5">
                    Al hacer la conversión a gas LP con nosotros podrás recargar tus cilindros con comodidad,
                    ¡Contamos con más 40 sucursales muy cerca de ti!
                </h2>
                <a href="#contacto" className="btn-cta mt-2 mb-4 fw-bold"> CONOCE MÁS </a>
            </div>
            {/* SEC5 */}
            <div className="faqs">
                <div className="container">
                    <h3 className="fw-bold fs-25 mb-5 text-center">Preguntas frecuentes sobre nuestro servicio de gas a domicilio</h3>
                    <div class="accordion" id="accordionExample">
                        {/* Pregunta1 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Es seguro convertir mi vehículo a gas LP?
                                </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Sí, el realizar la conversión a gas LP en tu vehículo no requiere cambios mecánicos que afecten su funcionalidad ni seguridad.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta2 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Qué mantenimiento adicional requiere un vehículo convertido a gas LP?
                                </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Los vehículos que pasan por el proceso de conversión a gas LP requieren un servicio de mantenimiento adicional que
                                    consiste en cambiar 2 filtros de gas LP cada 10,000 km, pero no te preocupes, su costo es muy bajo.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta3 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿Qué tipos de vehículos son aptos para la conversión a gas LP?
                                </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Cualquier tipo de vehículo puede pasar por la conversión a gas LP, siempre y cuando sea año 2008 en adelante.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta4 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Puedo seguir usando gasolina después de la conversión a gas LP?
                                </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Sí, los sistemas que se instalan para la conversión a gas LP permiten que el auto sea un híbrido entre gasolina y gas LP.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta5 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    ¿Cómo se instala el sistema de gas LP en mi vehículo?
                                </button>
                            </h3>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>En Gas Sultana realizamos la conversión a gas LP de la siguiente manera: </p>
                                    1. Examinanos tu vehículo para evaluar su compatibilidad con el sistema de gas LP. <br />
                                    2. Colocamos en tu vehículo un sistema de inyección de combustible que consta de un depósito, mangueras, sistema electrónico y de inyección. <br />
                                    3. Realizamos pruebas para garantizar la funcionalidad de la conversión de gasolina a gas LP.
                                </div>
                            </div>
                        </div>
                        {/* Pregunta6 */}
                        <div class="accordion-item">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    ¿Cuánto tiempo lleva el proceso de conversión a gas LP?
                                </button>
                            </h3>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Realizamos el proceso de conversión a gas LP en 24 horas.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC6 */}
            <div className="sec6 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mb-5">Comienza a usar gas LP y ahorra en cada kilómetro. <br /> ¡Haz tu conversión hoy!</h2>
                            <img src={footer} />
                        </div>
                        <div className="col-md-5">
                            <p className="mb-4">
                                ESCRÍBENOS, ESTAMOS PARA ATENDERTE
                            </p>
                            <div className="contacto" id="form">
                                <form onSubmit={onSubmitHandler}>
                                    <input type="text" name="nombre" placeholder="Nombre:" onChange={handleChange} value={lead.nombre || ""} required />
                                    <input type="text" name="email" placeholder="Email:" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="servicio" placeholder="Servicio:" onChange={handleChange} value={lead.servicio || ""} required />
                                    <input type="text" name="telefono" placeholder="Teléfono:" onChange={handleChange} value={lead.telefono || ""} required />
                                    <textarea name="mensaje" placeholder="Mensaje:" onChange={handleChange} value={lead.mensaje || ""} required />
                                    <button type="submit" className="btn-cta mt-3"> ¡CONTÁCTANOS! </button>
                                </form>
                            </div>
                        </div>
                        <div className="coml-md-1"></div>
                    </div>
                </div>
            </div>
            {/* FOOTER */}
            <Footer />
        </div>
    );
};

export default Vehicular;